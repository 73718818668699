<template>
  <v-lazy :min-height="avatarSize" :options="{ threshold: 0.1 }" transition="fade-transition">
    <v-chip v-bind="$attrs" :color="assetTag.color" :density="density ?? 'comfortable'" prepend-icon="$assetTag">
      <span class="text-high-emphasis">{{ assetTag.description }}</span>
    </v-chip>
  </v-lazy>
</template>

<script setup lang="ts">
const props = defineProps<{
  assetTag: Pick<AssetTag, "_id" | "color" | "description" | "isAutoGenerated">
  density?: "comfortable" | "compact" | "default"
}>()
const { assetTag, density } = toRefs(props)

const avatarSize = computed(() => {
  if (density?.value === "compact") return "1.3rem"
  if (density?.value === "comfortable" || !density.value) return "1.5rem"
  return "2rem"
})
</script>
